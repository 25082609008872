import React, { Component } from "react";
import "./App.css";
import axios from 'axios'
import Loader from "./components/Loader";
import lazyComponentLoader from './hoc/LazyLoader';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import ProtectedRoute from './hoc/ProtectedRoute';
import { isArray } from "util";
// import { message } from 'antd';
import DialogUtils from "./utils/DialogUtils";
import LayoutView from "./views/layout/LayoutView";
import AuthService from "./services/api/AuthService";
import PublicRoute from "./hoc/PublicRoute";

interface IAppState {
  isLoading:Boolean
  errorVisible:Boolean
}
const authService = new AuthService();
const Login:any = lazyComponentLoader(() => import('./views/auth/login/LoginView'));
const Forgot:any = lazyComponentLoader(() => import('./views/auth/forgot/ForgotView'));
const Reset:any = lazyComponentLoader(() => import('./views/auth/reset/ResetView'));
const RegistrationView:any = lazyComponentLoader(() => import('./views/auth/register/RegistrationView'));
const QRCodeView:any = lazyComponentLoader(() => import('./views/home/QRCodeView'));
class App extends Component<any, IAppState> {
  
  constructor(props:any) {
    super(props);
    //initialize state here
    this.state = {
        isLoading: false,
        errorVisible: true
    }

    const self = this;
    axios.interceptors.request.use(function (config) {
      // spinning start to show
      self.setState({isLoading: true});
      
      return config
    }, function (error) {
      return Promise.reject(error);
    });

    axios.interceptors.response.use(function (response) {
      // spinning hide
      self.setState({isLoading: false});
      
      return response;
    }, async (error) => {
      if (error.response) {
        this.showError(error.response.data.errors);
      } else {
        this.showError(error);
      }
      self.setState({isLoading: false});
      return Promise.reject(error);
    });
  }

  showError(errors:any)
  {
    let content:any = null;

    if(isArray(errors)) {
      // message.error(
        content = (< div>{
          errors.map( (item:any, index:number) => { return <div key={Math.random().toString(6)}>{item.message}</div>})
          }
        </div>)
    // );
    } else {
     content = errors.message.includes('Network Error') ? 'No Network Connection. Please make sure that Wi-Fi or Mobile Data is turned on, then try again.' : errors.message;
    }
    // const err = content? content.split('Error: ') : '';
    // DialogUtils.error(content);
    let showDialog:boolean = true;
    
    if(errors) {
      if(isArray(errors)) {
        {errors.map((value, index) => {
          if(value['context']['key'] == 'access_token' || value['context']['key'] == 'refresh_token') {
            showDialog = false;
          }
        })}
      }
    }
    if(showDialog) {
      DialogUtils.error(content);
    }
  }
  
  render() {

    return (
      <div>
        <Router>
            <Switch>
              <PublicRoute 
                auth={{ isLoggedIn: () => authService.isLoggedIn() }}
                exact path={['/', '/login']}
                component={Login}
              />
              <PublicRoute 
                auth={{ isLoggedIn: () => authService.isLoggedIn() }}
                exact path='/reset-password/:token'
                component={Reset}
              />
              <PublicRoute 
                auth={{ isLoggedIn: () => authService.isLoggedIn() }}
                exact path='/forgot-password'
                component={Forgot}
              />
              <PublicRoute 
                auth={{ isLoggedIn: () => authService.isLoggedIn() }}
                exact path='/registration'
                component={RegistrationView}
              />
              <ProtectedRoute
                exact 
                path={'/qrcode'} 
                component={QRCodeView}
              />
            </Switch>
        </Router>
        
        {this.state.isLoading ? <Loader /> : null}
      </div>
    );  
  }
}

export default App;