
/**
 * App configuration
 */
class APPConfig
{
    /**
     * api url endpoint
     * sample: http://localhost:3000/api
     */
    static API_URL:string = process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : '';
    static GOOGLE_CLIENT_ID:string = process.env.REACT_APP_GOOGLE_CLIENT_ID ? process.env.REACT_APP_GOOGLE_CLIENT_ID : ''
    static FACEBOOK_APP_ID:string = process.env.REACT_APP_FACEBOOK_APP_ID ? process.env.REACT_APP_FACEBOOK_APP_ID : ''
}

export default APPConfig;