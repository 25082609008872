import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './views/views-dir.less';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Router, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { GoogleOAuthProvider } from '@react-oauth/google';
import APPConfig from './config';

ReactDOM.render( 
    <Router history={createBrowserHistory()}>
        <GoogleOAuthProvider clientId={APPConfig.GOOGLE_CLIENT_ID}>
            <Route component={App}/>
        </GoogleOAuthProvider>
    </Router>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
