import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PublicRoute = ({auth, ...props}:any) => {
    const isLoggedIn = auth.isLoggedIn();
    return isLoggedIn
        ? (<Redirect to="/qrcode" />)
        : (<Route {...props} />)
}

export default PublicRoute;