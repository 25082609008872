import APIService from "./APIService";

class AuthService extends APIService
{

   login = async ({email, password}:any) => {
      const result = await this.post({email, password}, 'auth/login');
        
      return result ? result.data : null;
   }

   googleFacebookLogin = async (access_token:any) => {
      const result = await this.post({access_token}, 'auth/google-facebook-login');
        
      return result ? result.data : null;
   }

   forgotPassword = async ({email}:any) => {
      const result = await this.post({email}, 'auth/forgot-password');
        
      return result ? result.data : null;
   }

   resetPassword = async ({token, new_password, confirm_password}:any) => {
      const result = await this.post({token, new_password, confirm_password}, 'auth/reset-password');
        
      return result ? result.data : null;
   }

   register = async ({
      first_name,
      last_name,
      birthdate,
      gender,
      address, 
      email
   }:any) => {
         const result = await this.post({
            first_name,
            last_name,
            birthdate,
            gender,
            address, 
            email
         }, 'auth/register');
            
         return result ? result.data : null;
   }

   completeRegistration = async ({
      first_name,
      last_name,
      birthdate,
      gender,
      address, 
      email
   }:any) => {
         const result = await this.post({
            first_name,
            last_name,
            birthdate,
            gender,
            address, 
            email
         }, 'auth/complete-registration');
            
         return result ? result.data : null;
   }

   createCredentials = async ({passenger_id, email}:any) => {
         const result = await this.post({
            passenger_id,
            email
         }, 'auth/create-credentials');
            
         return result ? result.data : null;
   }

   isLoggedIn = () => {
      return localStorage.getItem('access_token') ? true : false;
   }


   test = async () => {
      const result = await this.get('test-api-call');

      return result ? result.data : null;
   }

   getUser = async (email: any) => {
      const result = await this.post({email}, 'auth/user');
      return result ? result.data : null;
   };

}

export default AuthService